<template>
<div>
  <van-nav-bar :title="totalCountStr" fixed placeholder="true"/>
  <div class="home-wrap" >
    <van-pull-refresh v-model="refresh" @refresh="onRefresh" style="height: 100% !important;">
      <div class="main-card-wrap flex-between" v-for="item in tableData">
      <div class="left flex-column flex-around">
        <div class="title">{{item.movieName}}  <span class="priceTitle">({{item.orderAmount/item.quantity}} * {{item.quantity}})</span></div>
        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
        <div class="subtitle">{{item.cityName}} | {{item.cinemaName}}</div>
        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
        <div class="otherTitle">{{item.playTimeText}} | {{item.hallName}}</div>
        <van-divider :style="{ padding: '0 0px',margin:'0 0px' }"/>
        <div>
<!--          <div class="btn flex-middle flex-center" @click="takeOrder(item.id)">-->
<!--            接单-->
<!--          </div>-->
          <van-button color="#8565bd" :style="{width:'100px',height:'32px',marginTop: '6px',borderRadius: '4px',fontSize:'14px'}" @click="takeOrder(item.id)" size="small" plain>接单</van-button>
        </div>
      </div>
      <div class="right flex-middle flex-center">
        <van-image
            width="90px"
            height="120px"
            fit="fit"
            :src="item.logo+'_200x200'"
        />
      </div>
    </div>
    </van-pull-refresh>
  </div>
</div>
</template>

<script>
import Api from '@/api-service'
export default {
  name: "Main.vue",
  data(){
    return {
      tableData:[],
      totalCountStr:'订单池',
      refresh:false,
    }
  },
  created() {
    this.fetchData();
  },
  methods:{
    onRefresh(){
      setTimeout(()=>{
        if (this.refresh) {
          this.tableData = [];
          this.refresh = false;
        }
        this.fetchData();
      },500)
    },
    fetchData(){
      Api.getOrderList().then(res=>{
        this.tableData = res.records;
        this.totalCountStr = '订单池'+(res.total>0?' ('+res.total+')':'');
      })
    },
    takeOrder(id){
      Api.takeOrder({id:id}).then(res=>{
        this.$toast.success("接单成功");
        this.fetchData();
      });
    }
  }
}
</script>

<style scoped lang="scss">
.home-wrap {
  height: 100%;
  background-color: #f3f3f3;

  .main-card-wrap {
    margin: 10px 10px;
    padding: 16px;
    border-radius: 6px;
    background-color: white;
    -moz-box-shadow: 2px 2px 3px #ccc;
    -webkit-box-shadow: 2px 2px 3px #ccc;
    box-shadow: 2px 2px 3px #ccc;
    .left {
      .title {
        font-size: 18px;
      }
      .subtitle {
        font-size: 14px;
        margin-top: 6px;
      }
      .priceTitle{
        font-size: 16px;
        color: #c75450;

      }
      .otherTitle{
        font-size: 14px;
        margin-top: 6px;
        color: #999;
      }
      .btn {
        height: 34px;
        width: 120px;
        color: white;
        margin-top: 6px;
        border-radius: 4px;
        background-color: #8565bd;
      }
    }
    .right {
      margin-right: 8px
    }
  }
}

</style>
